import React from "react";
import Layout from "../components/layout";
import MainMenu from "../components/MainMenu/MainMenu";
import Footer from "../components/Footer/Footer";
import CopyRights from "../components/Footer/CopyRights/CopyRights";
import SEO from "../components/seo";
import Header from "../components/Header/Header";
import HeadingCTA from "../components/HeadingCTA/HeadingCTA";
import PrivacyPolicy from "../components/Privacy/Privacy";
import { StaticImage } from "gatsby-plugin-image";

const Privacy = () => {
  return (
    <Layout>
      <MainMenu />
      <SEO
        seo={{
          metaTitle: "RDE Privacy policy",
          metaDescription: "RDE Privacy policy",
        }}
      />
      <Header
        image={<StaticImage src="../assets/images/contact/banner.png" />}
        breadcrumbs={[{ label: "RDE Privacy Policy" }]}
        halfHeight
      >
        <HeadingCTA dark title={"ONE RDE Privacy Policy"} />
      </Header>
      <PrivacyPolicy />
      <Footer />
      <CopyRights />
    </Layout>
  );
};

export default Privacy;
